import { DateTime } from 'luxon';
import _ from 'lodash';

const getDefaultSelectedYear = () => {
    let dateYear = new Date().getFullYear();
    if (new Date().getMonth() < 3) {
        dateYear -= 1;
    }
    const newYear = (dateYear + 1) % 100;
    return `${dateYear}-${newYear}`;
};

export const getUpdatedAtDateInFormat = (currentDate) => {
    const dateString = DateTime.fromISO(currentDate || new Date().toISOString()).toFormat('dd/MM/yyyy HH:mm');

    return dateString;
};
export const defaultSelectedYear = getDefaultSelectedYear();
export const YEARS = ['2023-24', '2024-25', '2025-26'];
export const UTSAV_YEAR_OPTIONS = ['2024-25', '2025-26'];
export const AHWAAL_MONTHS = [];

export const groupedGaonVastiWiseRows = (rows) => {
    let ogRows = [];
    const groupedrows = _.groupBy(rows, 'location.localized_name');
    Object.keys(groupedrows).forEach((i) => {
        ogRows = [...ogRows, ...groupedrows[i]];
    });
    return ogRows;
};
